import Vue from 'vue'
import VueRouter from 'vue-router'
import Content from '../views/content.vue'
import Material from '../views/material.vue'
import newMaterial from '@/views/newMaterial'
import Help from '../views/help.vue'
import Detail from '../views/detail.vue'
import Prize from '../views/prize.vue'
import Resource from '../views/resource.vue'
import Login from '../views/login.vue'
import ForgetPassword from '../views/forgetPassword.vue'
import Register from '../views/register.vue'
import Complete from '../views/complete.vue'
import UserInfo from '../views/userInfo.vue'
import userLayout from '@/components/userLayout'
import EditPassword from '../views/EditPassword.vue'
import Collect from '../views/collect.vue'
import AboutUs from '@/views/AboutUs'
import newAboutUs from '@/views/newAboutUs'
import CopyRight from '@/views/copyRight'
import DownloadSection from '@/views/downloadSection'
import VideoOnlineLearning from '@/views/videoOnlineLearning'
import Preview from '@/views/preview'
import VideoResource from '@/views/videoResource.vue'
import VideoLearning from '@/views/videoLearning.vue'
import course from '@/views/course.vue'
Vue.use(VueRouter)
import Cookies from 'js-cookie'
import store from '@/store'

const routes = [
  {
    path: '/',
    name: 'Content',
    meta: { home: true },
    component: Content
  },
  {
    path: '/material',
    name: 'Material',
    component: newMaterial
  },
  {
    path: '/help',
    name: 'help',
    component: Help
  },
  {
    path: '/detail',
    name: 'detail',
    component: Detail
  },
  {
    path: '/prize',
    name: 'prize',
    component: Prize
  },
	{
		path: '/prizeV1',
		name: 'prizeV1',
		component:  () => import("@/views/prizeV1.vue"),
	},
  {
    path: '/course2',
    name: 'course2',
    meta: { hide: true},
    component:  () => import("@/views/prizeV1Static.vue"),
  },
  {
    path: '/course3',
    name: 'course3',
    meta: { hide: true},
    component:  () => import("@/views/course3.vue"),
  },
  {
    path: '/course4',
    name: 'course4',
    meta: { hide: true},
    component:  () => import("@/views/course4.vue"),
  },
  {
    path: '/course5',
    name: 'course5',
    meta: { hide: true},
    component:  () => import("@/views/course5.vue"),
  },
  {
    path: '/resource',
    name: 'resource',
    component: Resource
  },
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: Login
  // },
	{
		path: "/loginRegistration",
		name: "loginRegistration",
		component: () => import("@/views/loginRegistration"),
	},
  {
    path: '/forgetPassword',
    name: 'forgetPassword',
    component: ForgetPassword
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/complete',
    name: 'complete',
    component: Complete
  },
  {
    path: '/userInfo',
    name: 'userInfo',
    component: userLayout
  },
  {
    path: '/editPassword',
    name: 'editPassword',
    component: EditPassword
  },
  {
    path: '/collect',
    name: 'collect',
    component: Collect
  },
  {
    path: '/about-us',
    name: 'aboutUs',
    component: newAboutUs
  },
  {
    path: '/copyRight',
    name: 'copyRight',
    component: CopyRight
  },
  {
    path: '/downloadSection',
    name: 'downloadSection',
    component: DownloadSection
  },
  {
    path: '/videoOnlineLearning',
    name: 'videoOnlineLearning',
    component: VideoOnlineLearning
  },
  {
    path: '/preview',
    name: 'preview',
    component: Preview
  },
  {
    path: '/videoResource',
    name: 'videoResource',
    component: VideoResource
  },
  {
    path: '/videoLearning',
    name: 'videoLearning',
    component: VideoLearning
  },
  {
    path: '/course',
    name: 'course',
    meta: { hide: true},
    component: course
  },
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

// 路由拦截器
router.beforeEach((to, from, next)=>{
	try {
    document.title = to.name === 'course' ? '历程' : '浙江省数字教材服务平台'
    document.title = to.name === 'course2' ? '下姜村的共同富裕梦' : '浙江省数字教材服务平台'
      if (localStorage.user) {
			store.commit('setUser',JSON.parse(localStorage.user))
		} else if (Cookies.get('commonName', { domain: 'zjeav.com' })) {
			const user = Cookies.get('commonName', { domain: 'zjeav.com' })
			store.commit('setUser',JSON.parse(user))
			localStorage.setItem('user', user)
		}else if(/token=\w+/.test(location.hash)){
        const token = location.hash.match(/(\?|&)token=([^&]+)/)[2]
        const userId = location.hash.match(/(\?|&)userId=([^&]+)/)[2]
        const userName = location.hash.match(/(\?|&)userName=([^&]+)/)[2]
        store.commit('setUser', {token,userId, userName})
        localStorage.setItem('user', JSON.stringify({userId,token, userName}))
      }
	}catch (e) {}
	next()
})

export default router
